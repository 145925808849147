<template>
  <div>
    <div v-if="type===null">
      <div class="md-layout-item md-size-100 mx-auto">
        <tabs
          :tab-name="['Experiment', 'Section', 'Screen', 'Element']"
          :tab-icon="['important_devices', 'crop_free', 'tv', 'star']"
          class="template-type-tabs"
          nav-pills-icons
          plain
          color-button="danger">
          <h3 class="title justify-content-center" slot="header-title">Choose template type</h3>

          <template slot="tab-pane-1">
            <md-card>
              <md-card-header>
                <h4 class="title">Create experiment template</h4>
              </md-card-header>
              <md-card-content>
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-field>
                      <label>Template name</label>
                      <md-input
                        v-model="template.name"
                        type="text"
                        required>
                      </md-input>
                    </md-field>
                    <md-chips v-model="template.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
                  </div>
                  <div class="md-layout-item md-size-25 md-small-size-100"></div>
                  <div class="md-layout-item md-size-25 md-small-size-100"></div>
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-button @click.native="save('experiment')" class="md-success"><md-icon>note_add</md-icon>Create and go to editor</md-button>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </template>
          <template slot="tab-pane-2">
            <md-card>
              <md-card-header>
                <h4 class="title">Create section template</h4>
              </md-card-header>
              <md-card-content>
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-field>
                      <label>Template name</label>
                      <md-input
                        v-model="template.name"
                        type="text"
                        required>
                      </md-input>
                    </md-field>
                    <md-chips v-model="template.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
                  </div>
                  <div class="md-layout-item md-size-25 md-small-size-100"></div>
                  <div class="md-layout-item md-size-25 md-small-size-100"></div>
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-button @click.native="save('section')" class="md-success save-button-top"><md-icon>note_add</md-icon>Create and go to editor</md-button>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </template>
          <template slot="tab-pane-3">
            <md-card>
              <md-card-header>
                <h4 class="title">Create screen template</h4>
              </md-card-header>
              <md-card-content>
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-field>
                      <label>Template name</label>
                      <md-input
                        v-model="template.name"
                        type="text"
                        required>
                      </md-input>
                    </md-field>
                    <md-chips v-model="template.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
                  </div>
                  <div class="md-layout-item md-size-25 md-small-size-100"></div>
                  <div class="md-layout-item md-size-25 md-small-size-100"></div>
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-button @click.native="save('screen')" class="md-success save-button-top"><md-icon>note_add</md-icon>Create and go to editor</md-button>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </template>
          <template slot="tab-pane-4">
            <md-card>
              <md-card-header>
                <h4 class="title">Create element template</h4>
              </md-card-header>
              <md-card-content>
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-field>
                      <label>Template name</label>
                      <md-input
                        v-model="template.name"
                        type="text"
                        required>
                      </md-input>
                    </md-field>
                    <md-chips v-model="template.tags" class="md-success" md-placeholder="Enter tags here..."></md-chips>
                  </div>
                  <div class="md-layout-item md-size-25 md-small-size-100"></div>
                  <div class="md-layout-item md-size-25 md-small-size-100"></div>
                  <div class="md-layout-item md-size-25 md-small-size-100">
                    <md-button @click.native="save('element')" class="md-success save-button-top"><md-icon>note_add</md-icon>Create and go to editor</md-button>
                  </div>
                </div>
              </md-card-content>
            </md-card>
          </template>
        </tabs>
      </div>
    </div>
  </div>
</template>
<script>
  import { Tabs } from '@/components'
  import Editor from '../Experiments/Editor.vue'
  import swal from 'sweetalert2'

  export default {
    data() {
      return {
        type: null,
        template: {
          name: null,
          tags: [],
          code: null
        }
      }
    },
    components: {
      Tabs,
      Editor
    },
    methods: {
      save (type) {
        var self = this
        var formData = new FormData()
        formData.append('admin_id', localStorage.getItem('admin_id'))
        formData.append('type', type)
        if (this.template.name) formData.append('name', this.template.name)
        if (this.template.tags) formData.append('tags', this.template.tags)
        else formData.append('tags', '')
        var xhr = new XMLHttpRequest()
        xhr.open('POST', self.$root.apiUrl + 'v1/templates')
        xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
        xhr.onload = function () {
          if (xhr.status === 401) self.$router.push({name: 'Login'})
          var response = JSON.parse(xhr.responseText)
          if (xhr.status === 201) {
            self.notifyVue('top','right')
            self.$router.push({name: 'Edit Template', params: {id: response.response.id}})
          } else {
            var message
            if (response.response && response.response[0] && response.response[0].message)
              message = response.response[0].message
            else
              message = 'Unknown error'
            self.showSweetAlertError(message)
          }
        }
        xhr.send(formData)
      },
      showSweetAlertError (message) {
        swal({
          title: 'An error occured!',
          text: message,
          type: 'warning',
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: 'Try again',
          cancelButtonClass: 'md-button md-success',
          buttonsStyling: false
        })
      },
      notifyVue (verticalAlign, horizontalAlign) {
        this.$notify(
          {
            message: 'Template saved succesfully.',
            icon: 'note_add',
            horizontalAlign: horizontalAlign,
            verticalAlign: verticalAlign,
            type: 'success'
          }
        )
      }
    }
  }
</script>

